export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Master',
    icon: 'DiscIcon',
    children: [
      {
        title: 'Currency',
        route: 'currency.index',
        permission: 'show currency type',
      },
    ],
  },
  {
    title: 'Commodity',
    route: 'commodities.index',
    icon: 'BoxIcon',
    permission: 'show commodities',
  },
  {
    title: 'Auction',
    route: 'auctions.index',
    icon: 'ListIcon',
    permission: 'show auctions',
  },
  {
    title: 'SOB',
    route: 'auctions.sob',
    icon: 'DollarSignIcon',
    permission: 'update sobo',
  },
  {
    title: 'Block Trade',
    route: 'block-trade.index',
    icon: 'LockIcon',
    permission: 'list block trade',
  },
  {
    title: 'Collateral Type',
    route: 'collateral-type.index',
    icon: 'PieChartIcon',
    permission: 'show collateral type'
  },
  {
    title: 'Transaction',
    icon: 'RepeatIcon',
    children: [
      {
        title: 'Add Auction Order',
        route: 'auctions.transactions.add',
        permission: 'add auction transaction',
      },
      {
        title: 'Auction Order',
        route: 'auctions.transactions.index',
        permission: 'show auction transactions',
      },
      {
        title: 'Matched List',
        route: 'auctions.transactions.match',
        permission: 'show auction transactions',
      },
      {
        title: 'Trade Allocation',
        route: 'auctions.transactions.trade-allocations',
        permission: 'show auction transactions',
      },
      // {
      //   title: 'Pending Settlement',
      //   route: 'auctions.transactions.pending-settlement',
      //   permission: 'show auction transactions',
      // },
      {
        title: 'Commodity Transaction',
        route: 'commodity-transactions.index',
        permission: 'list commodity transactions',
      },
      {
        title: 'Cash Adjustment',
        route: 'cash-adjustment-transactions.index',
        permission: 'transaction history cash user'
      },
      {
        title: 'Cash Transaction',
        route: 'cash-transactions.index',
        permission: 'transaction history cash user',
      },
    ],
  },
  {
    title: 'Topup',
    icon: 'DownloadIcon',
    children: [
      {
        title: 'Topup Cash',
        route: 'topup.index',
        permission: 'show topups',
      },
      {
        title: 'Topup Collateral',
        route: 'topup-collateral.index',
        permission: 'show topups'
      },
      {
        title: 'Topup Bank',
        route: 'payment-bank.index',
        permission: 'show bankaccount',
      },
    ],
  },
  {
    title: 'Cash Withdraw',
    icon: 'UploadIcon',
    children: [
      {
        title: 'Cash Withdraw',
        route: 'withdraw.index',
        permission: 'show withdraws',
      },
      {
        title: 'User Bank',
        route: 'user-bank.index',
        permission: 'show user-bankaccount',
      },
    ],
  },
  {
    title: 'Clearing Report',
    icon: 'PaperclipIcon',
    children: [
      {
        title: 'Recap Clearing',
        route: 'report.recap-clearing',
        permission: 'report recap clearing'
      },
      {
        title: 'Clearing Statement',
        route: 'report.clearing-statement',
        permission: 'report clearing statement'
      },
      {
        title: 'Cash Transfer',
        route: 'report.cash-transfer',
        permission: 'report cash transaction'
      },
      {
        title: 'Commodity Stock',
        route: 'report.commodity-stock',
        permission: 'report commodity stock'
      },
      {
        title: 'Fee Transaction',
        route: 'report.fee-transaction',
        permission: 'report fee transaction'
      },
      {
        title: 'Outstanding Delivery',
        route: 'report.outstanding-delivery',
        permission: 'report outstanding delivery'
      },
      {
        title: 'Transaction History',
        route: 'report.transaction-history',
        permission: 'report transaction history',
      },
      {
        title: 'Settlement Price',
        route: 'report.settlement-price',
        permission: 'report settlement price'
      },
    ],
  },
  {
    title: 'Commodity Withdraw',
    route: 'commodity-withdraw.index',
    icon: 'TruckIcon',
    permission: 'show commodity withdraws',
  },
  {
    title: 'Users/Trader',
    route: 'users.index',
    icon: 'UsersIcon',
    permission: 'show users',
  },
  {
    title: 'Depository',
    route: 'warehouse.index',
    icon: 'MapPinIcon',
    permission: 'show warehouses',
  },
  {
    title: 'Web Content',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Page',
        route: 'page.index',
        permission: 'show contents',
      },
      {
        title: 'News',
        route: 'news.index',
        permission: 'show contents',
      },
      {
        title: 'FAQ',
        route: 'faq.index',
        permission: 'show contents',
      },
      {
        title: 'Partner List',
        route: 'partner.index',
        permission: 'show contents',
      },
      {
        title: 'Meta Image',
        route: 'image.index',
        permission: 'show contents',
      },
      {
        title: 'Meta Text',
        route: 'text.index',
        permission: 'show contents',
      },
      {
        title: 'Slider Images',
        route: 'slider.index',
        permission: 'show contents',
      },
      {
        title: 'Join Now Images',
        route: 'join-now-images.index',
        permission: 'show contents',
      },
      // {
      //   title: 'Information',
      //   route: 'information.index',
      //   permission: 'show contents'
      // },
      // {
      //   title: 'Rule',
      //   route: 'rules.index',
      //   permission: 'show contents'
      // },
    ],
  },
  {
    title: 'Admin User',
    icon: 'KeyIcon',
    children: [
      {
        title: 'Admin Management',
        route: 'admin.index',
        permission: 'show admins',
      },
      {
        title: 'Role Management',
        route: 'role.index',
        permission: 'roles and permission management',
      },
    ],
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Holiday Management',
        route: 'holiday.index',
        permission: 'show holiday',
      },
      {
        title: 'Commodity Category',
        route: 'commodity-category.index',
        permission: 'show commodity categories',
      },
      {
        title: 'Setting',
        route: 'setting.index',
        permission: 'list settings',
      },
    ],
  },
  {
    title: 'User Logs',
    route: 'activity-log.index',
    icon: 'ArchiveIcon',
    permission: 'show activity log',
  },
]
